import {Box, Container, Grid, Typography} from "@mui/material";
import Link from "next/link";
import React from "react";

function LandingOfferComponent({title}) {
  return (
    <>
      <Container maxWidth="lg">
        <Typography
          component="p"
          sx={{
            fontSize: { xs: '16px', lg: '24px' },
            marginBottom: '20px',
            marginTop: { xs: '27px', lg: '70px' },
            textTransform: 'uppercase',
            fontFamily: 'Avenir-Bold',
            paddingLeft: '20px',
            textAlign: "center"
          }}
        >
          {title}
        </Typography>
      </Container>
      <Box
        sx={{
          backgroundColor: '#FFF',
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            justifyContent="center"
            sx={{
              gap: "1rem"
            }}
          >
            <Grid
              item
              xs={12}
              md={4}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Link href="/salja#maklare" passHref legacyBehavior>
                <Box
                  component="a"
                  sx={{
                    textDecoration: 'none',
                    color: 'inherit',
                    marginX: '10px',
                    paddingX: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    cursor: 'pointer',
                    borderRadius: '20px',
                    boxShadow: '0 12px 28px rgba(140,149,159,0.3)',
                    '&:hover': {
                      boxShadow: '0 12px 28px rgb(140 149 159 / 60%)',
                    },
                    transition: 'all 0.1s ease-in-out',
                    paddingTop: '40px',
                    position: 'relative',
                    flexGrow: 1
                  }}
                >
                  <Box
                    sx={{
                      width: '130px',
                      height: '30px',
                      backgroundColor: '#FBCEB1',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'absolute',
                      top: '15px',
                    }}
                  >
                    <Typography
                      sx={{ textTransform: 'uppercase', textAlign: 'center' }}
                    >
                      toppval
                    </Typography>
                  </Box>
                  <Box
                    component="img"
                    src="/images/g1.svg"
                    alt="g1"
                    sx={{
                      mt: '30px',
                      width: { xs: '95px', md: '150px' },
                      height: { xs: '95px', md: '150px' },
                    }}
                  />
                  <Typography
                    component="p"
                    className="font-Avenir-Bold"
                    sx={{
                      fontSize: { xs: '18px', lg: '30px' },
                      marginBottom: { xs: '11px', lg: '20px' },
                      marginTop: { xs: '18px', lg: '40px' },
                      // textTransform: 'uppercase',
                    }}
                  >
                    Mäklare
                  </Typography>
                  <Typography
                    component="p"
                    className="font-Roboto"
                    sx={{
                      fontSize: { xs: '14px', lg: '18px' },

                      textAlign: 'center',
                    }}
                  >
                    {`Allt du behöver för att sälja din bostad till bästa pris. Behåll
              kontrollen och betala Sveriges lägsta mäklararvode. Du sköter
              visningen - vi sköter resten! `}
                    <br />
                    <br />
                  </Typography>
                  {/*<Typography>
                  För bostadsrätt
                </Typography>
                <Typography
                  sx={{
                    fontSize: '30px',
                    fontFamily: 'Avenir-Bold',
                    mb: '10px'
                  }}
                >
                  {`${numberWithSpaces(19900)} kr`}
                </Typography>
                <Typography>
                  För fastighet
                </Typography>
                <Typography
                  sx={{
                    fontSize: '30px',
                    fontFamily: 'Avenir-Bold',
                    mb: '10px',
                  }}
                >
                  {`${numberWithSpaces(23900)} kr`}
                </Typography>*/}

                  <Typography
                    component="p"
                    className="font-Roboto"
                    sx={{
                      fontSize: { xs: '20px', lg: '24px' },
                      mb: 3,
                    }}
                  >
                    Läs mer
                  </Typography>
                </Box>
              </Link>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Link href="/salja#kontrakt" passHref legacyBehavior>
                <Box
                  component="a"
                  sx={{
                    textDecoration: 'none',
                    color: 'inherit',
                    marginX: '10px',
                    paddingX: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    borderRadius: '20px',
                    boxShadow: '0 12px 28px rgba(140,149,159,0.3)',
                    '&:hover': {
                      boxShadow: '0 12px 28px rgb(140 149 159 / 60%)',
                    },
                    transition: 'all 0.1s ease-in-out',
                    paddingTop: '40px',
                    position: 'relative',
                    flexGrow: 1
                  }}
                >
                  <Box
                    component="img"
                    src="/images/g2.svg"
                    alt="g1"
                    sx={{
                      mt: '30px',
                      width: { xs: '95px', md: '150px' },
                      height: { xs: '95px', md: '150px' },
                    }}
                  />
                  <Typography
                    component="p"
                    className="font-Avenir-Bold"
                    sx={{
                      fontSize: { xs: '18px', lg: '30px' },
                      marginBottom: { xs: '11px', lg: '20px' },
                      marginTop: { xs: '18px', lg: '40px' },
                      // textTransform: 'uppercase',
                    }}
                  >
                    Kontrakt
                  </Typography>
                  <Typography
                    component="p"
                    className="font-Roboto"
                    sx={{
                      fontSize: { xs: '14px', lg: '18px' },
                      textAlign: 'center',
                      mb: 3,
                    }}
                  >
                    {`Har du redan hittat en köpare till din bostad? Anlita en av våra
              mäklare för ett skrivuppdrag. Mäklaren ordnar all dokumentation
              på ett tryggt och effektivt sätt.`}
                  </Typography>

                  {/*<Typography>
                  För bostadsrätt
                </Typography>
                <Typography
                  sx={{
                    fontSize: '30px',
                    fontFamily: 'Avenir-Bold',
                    mb: '10px',
                  }}
                >
                  {`${numberWithSpaces(9900)} kr`}
                </Typography>
                <Typography>
                  För fastighet
                </Typography>
                <Typography
                  sx={{
                    fontSize: '30px',
                    fontFamily: 'Avenir-Bold',
                    mb: '10px',
                  }}
                >
                  {`${numberWithSpaces(12900)} kr`}
                </Typography>*/}
                  <Typography
                    component="p"
                    className="font-Roboto"
                    sx={{
                      fontSize: { xs: '20px', lg: '24px' },
                      mb: 3,
                    }}
                  >
                    Läs mer
                  </Typography>
                </Box>
              </Link>
            </Grid>

            {/*<Grid
            item
            xs={12}
            md={4}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Link href="/salja#annons" passHref>
              <Box
                component="a"
                sx={{
                  textDecoration: 'none',
                  color: 'inherit',
                  marginX: '10px',
                  paddingX: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderRadius: '20px',
                  boxShadow: '0 12px 28px rgba(140,149,159,0.3)',
                  '&:hover': {
                    boxShadow: '0 12px 28px rgb(140 149 159 / 60%)',
                  },
                  transition: 'all 0.1s ease-in-out',
                  paddingTop: '40px',
                  position: 'relative',
                }}
              >
                <Box
                  component="img"
                  src="/images/g3.svg"
                  alt="g1"
                  sx={{
                    mt: '30px',
                    width: { xs: '95px', md: '150px' },
                    height: { xs: '95px', md: '150px' },
                  }}
                />
                <Typography
                  component="p"
                  className="font-Avenir-Bold"
                  sx={{
                    fontSize: { xs: '18px', lg: '30px' },
                    marginBottom: { xs: '11px', lg: '20px' },
                    marginTop: { xs: '18px', lg: '40px' },
                    // textTransform: 'uppercase',
                  }}
                >
                  Annons
                </Typography>
                <Typography
                  component="p"
                  className="font-Roboto"
                  sx={{
                    fontSize: { xs: '14px', lg: '18px' },
                    mb: 3,
                    textAlign: 'center',
                  }}
                >
                  {`Annonsera din bostad utan mäklare hos Privatmäklaren och på
              Booli. Skapa en proffsig annons och nå ut till tusentals
              spekulanter på egen hand. Perfekt för dig som vill "testa
              marknaden".`}
                </Typography>

                <Typography
                  sx={{
                    fontSize: '30px',
                    fontFamily: 'Avenir-Bold',
                    my: '10px',
                  }}
                >
                  {`${numberWithSpaces(790)} kr`}
                </Typography>
                <Typography
                  component="p"
                  className="font-Roboto"
                  sx={{
                    fontSize: { xs: '20px', lg: '24px' },
                    mb: 3,
                  }}
                >
                  Läs mer
                </Typography>
              </Box>
            </Link>
          </Grid>*/}
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default LandingOfferComponent
