import React from 'react';
// import Box from '@mui/material/Box';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import CloseIcon from '@mui/icons-material/Close';
// import AppBar from '@mui/material/AppBar';
import Link from 'next/link';
import Image from 'next/image';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { CustomerReviewCardProps } from '../../../constants/types';

const CustomerReviewCard = ({
  isSlider = false,
  ...rest
}: CustomerReviewCardProps) => {
  const refReviewText = React.useRef(null);
  if (!isSlider) {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          overflow: 'hidden',
          position: 'relative',
          alignItems: 'center',
          color: '#2F2E41',
        }}
      >
        <Box
          sx={{
            minWidth: { xs: '100%', md: '50%' },
            display: 'flex',
            justifyContent: { xs: 'center', md: 'end' },
            paddingRight: { md: '30px' },
          }}
        >
          <Image
            src={`https:${rest.imagePath}`}
            width={450}
            height={300}
            alt=""
          />
        </Box>
        <Box>
          <Box
            component="img"
            src="/images/bigDoublequotes.svg"
            mt="13px"
            width={21}
            height={16}
          />
          <Typography
            component="p"
            mt="10px"
            className="font-Avenir-Bold"
            sx={{
              fontSize: { xs: '20px', md: '24px' },
            }}
          >
            {rest.title}
          </Typography>
          <Typography
            component="p"
            mt="10px"
            className="font-Avenir"
            sx={{
              fontSize: { xs: '14px', md: '16px' },
            }}
          >
            {rest.content}
          </Typography>
          <Typography
            component="p"
            mt="10px"
            className="font-Avenir"
            fontSize="14px"
          >
            {rest.author}
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Link href="/omdomen" passHref legacyBehavior>
      <Box
        component="a"
        sx={{
          textDecoration: 'none',
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          height: { md: '300px', xs: '600px' },
          overflow: 'hidden',
          position: 'relative',
          color: '#2F2E41',
        }}
      >
        <Box
          sx={{
            minWidth: '50%',
            display: 'flex',
            justifyContent: { xs: 'center', md: 'end' },
            paddingRight: { md: '30px' },
          }}
        >
          <Image
            src={`https:${rest.imagePath}`}
            width={450}
            height={300}
            alt=""
          />
        </Box>

        {isSlider && (
          <Box sx={{}} ref={refReviewText}>
            <Box
              className=""
              sx={{
                position: 'absolute',
                width: '100%',
                background: 'linear-gradient(#F2F4F700 50%, #F2F4F7FF 94%)',
                opacity: 1,
                display: 'flex',
                alignItems: 'flex-end',
                transition: '.2s all ease-in-out',
                height: '100%',
                top: '0px',
              }}
            >
              <Typography
                sx={{
                  marginTop: '16px',
                  fontFamily: 'Avenir',
                  fontSize: '14px',
                  lineHeight: '25px',
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                }}
              >
                {`Se fler kundomdömen >`}
              </Typography>
            </Box>
            <Box
              component="img"
              src="/images/bigDoublequotes.svg"
              mt="13px"
              width={21}
              height={16}
            />
            <Typography
              component="p"
              mt="10px"
              className="font-Avenir-Bold"
              sx={{
                fontSize: { xs: '20px', md: '24px' },
              }}
            >
              {rest.title}
            </Typography>
            <Typography
              component="p"
              mt="10px"
              className="font-Avenir"
              sx={{
                fontSize: { xs: '14px', md: '16px' },
              }}
            >
              {rest.content}
            </Typography>
            <Typography
              component="p"
              mt="10px"
              className="font-Avenir"
              fontSize="14px"
            >
              {rest.author}
            </Typography>
          </Box>
        )}
      </Box>
    </Link>
  );
};

export default CustomerReviewCard;
