import React, { ReactNode } from 'react';
import NextLink from 'next/link';
import { connect } from 'react-redux';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from '@mui/material';
import Image from "next/image";

interface ContactProps {
  data: string;
  entries: any[];
  assets: any[];
}

const Contact = (props: ContactProps) => {
  const { data, entries, assets } = props;

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [address, setAddress] = React.useState(null);
  const [phone, setPhone] = React.useState([]);
  const [avatar, setAvatar] = React.useState();

  React.useEffect(() => {
    const res = entries.find((entry) => entry.sys.id === data).fields;
    if (res.firstName) setFirstName(res.firstName);
    if (res.lastName) setLastName(res.lastName);
    if (res.title) setTitle(res.title);
    if (res.email) setEmail(res.email);
    if (res.address) setAddress(res.address);
    if (res.phone) setPhone(res.phone);
    if (res.image)
      setAvatar(
        assets.find((asset) => asset.sys.id === res.image.sys.id).fields.file
          .url
      );
  }, []);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <Card sx={{ width: { xs: 300, md: 345 } }}>
          {avatar && (
            <Image
              src={`https:${avatar}?w=345`}
              width={345}
              height={300}
              layout={"responsive"}
              alt={`${firstName} ${lastName}`}
              objectFit={"cover"}
            />
          )}
          <CardContent>
            <Typography sx={{ fontSize: 18 }} component="div">
              {title}
            </Typography>
            <Typography
              sx={{ fontSize: 28, textTransform: 'uppercase' }}
              color="text.secondary"
            >
              {`${firstName} ${lastName}`}
            </Typography>
            <Typography sx={{ fontSize: 20 }} component="div">
              {phone.map((item) => (
                <>
                  {item} <br />
                </>
              ))}
            </Typography>
            <Typography sx={{ fontSize: 20 }} component="div">
              {email}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    entries: state.main.entries,
    assets: state.main.assets,
  };
};

export default connect(mapStateToProps, null)(Contact);
