import React, { ReactNode } from 'react';
import NextLink from 'next/link';
import { connect } from 'react-redux';
import {
  Modal,
  Fade,
  Backdrop,
  Box,
  Typography,
  Button,
  Grid,
} from '@mui/material';

import Contact from '../Contact';

interface ContactListProps {
  data: string;
  entries: any[];
}

const ContactList = (props: ContactListProps) => {
  const { data, entries } = props;

  const [title, setTitle] = React.useState('');
  const [contacts, setContacts] = React.useState([]);

  React.useEffect(() => {
    const res = entries.find((entry) => entry.sys.id === data);
    setTitle(res.fields.title);
    setContacts(res.fields.contacts);
  }, []);

  return (
    <>
      <Grid
        container
        my={3}
        mx={0}
        sx={{ width: '100%', position: 'relative' }}
      >
        <Box
          sx={{ position: 'absolute', top: '-150px' }}
          id="medarbetare"
        ></Box>
        {contacts.map((contact, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Contact data={contact.sys.id} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    entries: state.main.entries,
  };
};

export default connect(mapStateToProps, null)(ContactList);
